function index() {
  return import(/* webpackChunkName: "page-index" */ '@/pages/index.vue')
}
function course_list_index() {
  return import(
    /* webpackChunkName: "page-course-list-index" */ '@/pages/course/list/index.vue'
  )
}
function course_statistics_index() {
  return import(
    /* webpackChunkName: "page-course-statistics-index" */ '@/pages/course/statistics/index.vue'
  )
}
function course_detail__id() {
  return import(
    /* webpackChunkName: "page-course-detail-id" */ '@/pages/course/detail/_id.vue'
  )
}
function course_preview__id() {
  return import(
    /* webpackChunkName: "page-course-preview-id" */ '@/pages/course/preview/_id.vue'
  )
}
function student_detail__id() {
  return import(
    /* webpackChunkName: "page-student-detail-id" */ '@/pages/student/detail/_id.vue'
  )
}

export default [
  {
    name: 'index',
    path: '/',
    component: index,
  },
  {
    name: 'course-list',
    path: '/course/list',
    component: course_list_index,
  },
  {
    name: 'course-statistics',
    path: '/course/statistics',
    component: course_statistics_index,
  },
  {
    name: 'course-detail-id',
    path: '/course/detail/:id?',
    component: course_detail__id,
  },
  {
    name: 'CoursePreview',
    path: '/course/preview/:id?',
    component: course_preview__id,
    meta: {
      fullscreen: true,
    },
  },
  {
    name: 'student-detail-id',
    path: '/student/detail/:id?',
    component: student_detail__id,
  },
]
