import { fetchBanner,fetchNewsInfo,fetchAgenda } from '@/services/conferences'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  banner: [],
  newsInfo:{},
  agenda:[]
})

// getters
const getters = {
}

// actions
const actions = {
  async fetchBanner({ commit, state }, payload) {
    const data = await fetchBanner(payload)

    commit('setBanner', data)
  },
  async fetchNewsInfo({ commit, state }, payload) {
    const data = await fetchNewsInfo(payload)
  
    commit('setNewsInfo', data)
  },
  async fetchAgenda({ commit, state }, payload) {
    const data = await fetchAgenda(payload)
  
    commit('setAgenda', data)
  }
}

// mutations
const mutations = {
  setBanner(state, payload) {
    state.banner = payload
  },
  setNewsInfo(state, payload) {
    state.newsInfo = payload
  },
  setAgenda(state, payload) {
    state.agenda = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}