<template>
<div ref="chart" style="height: 450px;"></div>
</template>

<script>
export default {
  name: 'ChartBar',
  props: {
    value: Array,
    option: Object
  },
  data() {
    return {}
  },
  methods: {
    init(data) {
      const that = this
      const chartDom = this.$refs['chart']
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            rotate: 35,  // 35度角倾斜显示
          },
          data: this.value.map(item => item.name)
        },
        yAxis: {
          minInterval: 1
        },
        animationDurationUpdate: 500,
        series: [{
          type: 'bar',
          barWidth: '50px',
          dimensions: ['x', 'y', 'groupId', 'childGroupId'],
          encode: {
            x: 'x',
            y: 'y',
            itemGroupId: 'groupId',
            itemChildGroupId: 'childGroupId'
          },
          data: this.value,
          universalTransition: {
            enabled: true,
            divideShape: 'clone'
          },
          itemStyle: {
            normal: {
              color(params) {
                const colorList = [
                  '#1a47e7',
                  '#7e62f3',
                  '#2f65bd',
                  '#5dbfeb',
                  '#4dc9bf'
                ]
                const colorItem = that.option && that.option.color ? that.option.color[params.dataIndex] : colorList[params.dataIndex]

                return colorItem
              }
            }
          }
        }],
        ...this.option
      }

      option && myChart.setOption(option)

      myChart.on('click', function (event) {
        if (event.data) {
          if (!event.data.children) {
            return
          }

          myChart.setOption({
            title: {
              text: event.data.name,
              subtext: '',
              left: 'center'
            },
            xAxis: {
              type: 'category',
              axisLabel: {
                show: true,
                rotate: 35,  // 35度角倾斜显示
              },
              data: event.data.children.map(item => item.name)
            },
            yAxis: {
              minInterval: 1
            },
            animationDurationUpdate: 500,
            series: [{
              type: 'bar',
              dimensions: ['x', 'y', 'groupId', 'childGroupId'],
              encode: {
                x: 'x',
                y: 'y',
                itemGroupId: 'groupId',
                itemChildGroupId: 'childGroupId'
              },
              data: event.data.children,
              universalTransition: {
                enabled: true,
                divideShape: 'clone'
              }
            }],
            graphic: [{
              type: 'text',
              left: 0,
              top: 0,
              style: {
                text: '返回',
                fontSize: 14
              },
              onclick: function () {
                myChart.setOption(option);
              }
            }]
          })
        }
      })
    }
  },
  mounted() {
    this.init(this.value)
  },
  watch: {
    value: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  }
}
</script>
