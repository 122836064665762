import { conferencesInfo } from '@/services/common'
import dayjs from 'dayjs'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  conferencesInfo: {}
})

// getters
const getters = {
}

// actions
const actions = {
  async fetchConferencesInfo({ commit, state }) {
    const data = await conferencesInfo()

    if (data.impDate) {
      data.impDate = data.impDate.map(item => {
        item.date = dayjs(item.date).format('YYYY年MM月DD日')

        return item
      })
    }

    commit('setConferencesInfo', data)
  }
}

// mutations
const mutations = {
  setConferencesInfo(state, payload) {
    state.conferencesInfo = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}