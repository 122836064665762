<template>
  <div class="course-card" @click="toCoursewareList">
    <div class="course-card-head">
      <h2 class="course-card-title">{{ title }}</h2>
      <i :class="['course-card-badge', sort < 3 ? `course-card-badge-${sort + 1}` : 'course-card-badge-num']">{{ sort + 1 }}</i>
    </div>
    <div class="course-card-body">
      <div v-if="cover" class="course-card-cover">
        <img :src="cover" />
      </div>
      <h3 class="course-card-subtitle" v-if="subtitle">{{ subtitle }}</h3>
      <div class="course-card-description">{{ description }}</div>
    </div>
    <div class="course-card-footer">
      <div class="course-card-actions">
        <a href="javascript:;" :class="[liked && 'active']" @click="handleLikeToggle" title="点赞">
          <i :class="['iconfont', liked ? 'icon-like-fill' : 'icon-like']" />
          <span>{{ likes }}</span>
        </a>
        
        <a href="javascript:;" title="评论">
          <i class="iconfont icon-wechat-fill" />
          <span>{{ comments }}</span>
        </a>

        <a href="javascript:;" :class="[collected && 'active']" @click="handleCollectToggle" title="收藏">
          <i :class="['iconfont', collected ? 'icon-star-fill' : 'icon-star']" />
          <span>{{ collections }}</span>
        </a>

        <a href="javascript:;" title="时长">
          <i class="iconfont icon-time-circle-fill" />
          <span>{{ duration }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseCard',
  props: {
    id: Number,
    // 排序
    sort: {
      type: Number,
      default: 0
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 子标题
    subtitle: {
      type: String,
      default: ''
    },
    // 摘要
    description: {
      type: String,
      default: ''
    },
    // 封面图
    cover: {
      type: String,
      default: ''
    },
    // 是否点赞
    liked: {
      type: Boolean,
      default: false
    },
    // 点赞数量
    likes: {
      type: Number,
      default: 0
    },
    // 评论数量
    comments: {
      type: Number,
      default: 0
    },
    // 是否已收藏
    collected: {
      type: Boolean,
      default: false
    },
    // 收藏数量
    collections: {
      type: Number,
      default: 0
    },
    // 课件时长
    duration: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {
    fire() {
      this.$nextTick(() => {
        this.$emit('update', this.$options.propsData, this.sort)
      })
    },
    handleLikeToggle() {
      let liked = this.liked
      let likes = this.likes

      liked = !liked

      if (liked) {
        likes++
      } else {
        likes--
      }

      this.$emit('update:liked', liked)
      this.$emit('update:likes', likes)
      
      this.fire()
    },
    handleCollectToggle() {
      let collected = this.collected
      let collections = this.collections

      collected = !collected

      if (collected) {
        collections++
      } else {
        collections--
      }

      this.$emit('update:collected', collected)
      this.$emit('update:collections', collections)
      
      this.fire()
    },
	toCoursewareList(){
		this.$router.push(
		{
			path:`/course/detail/${this.id}`,
			query:{},
		});
	}
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.course-card {
  position: relative;
  box-sizing: border-box;
  padding: 10px 12px;
  background-color: #ffffff;
  border-radius: 12px;
  border-bottom: 5px solid rgba(0, 0, 255, .1);
  box-shadow: 0 0 10px #eef3fc;
  cursor: pointer;
  &:hover{
	  background: #fff;
	   -webkit-box-shadow: 0 0 15px #ced4ec;
	  box-shadow: 0 0 15px #ced4ec;
	  border-bottom: 5px solid #0356ca;
	   .course-card-title{
		  
		  &:before{
		  	 width: 285px;
			-webkit-transition: 0.2s linear;
			transition: 0.2s linear;
			height: 4px;
			background:$--cw-primary-light-1;
		  }
	  }
	  
  }
  &-title {
    color: #666;
    font-size: 20px;
    line-height: 1.5;
    padding: 15px 0;
    font-weight: 600;
	position: relative;
	margin-bottom: 10px;
	border-bottom:1px solid #E7E7E7;
	&:before{
		content: '';
		position:absolute;
		left: 0;
		bottom: 0px;
		width: 0;
		height: 1px;
		background:$--cw-primary-light-1;
	}
	
  }

  &-subtitle {
    color: #414141;
    font-size: $--cw-font-size;
    line-height: 1.5;
    padding: 5px 0;
  }

  &-description {
    color: $--cw-color-text-description;
    line-height: 22px;
    text-indent: 2em;
    overflow: hidden;
    font-size: $--cw-font-size;
    font-weight: 400;
  }

  &-body {
    height: 90px;
    margin-bottom: 14px;
    overflow: hidden;
  }
}

.course-card-badge {
  position: absolute;
  right: 9px;
  top: 9px;

  &-1, &-2, &-3 {
    width: 32px;
    height: 42px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    line-height: 150px;
    overflow: hidden;
  }

  &-1 {
    background-image: url('@/assets/images/components/CourseCard/medal-1.png');
  }

  &-2 {
    background-image: url('@/assets/images/components/CourseCard/medal-2.png');
  }

  &-3 {
    background-image: url('@/assets/images/components/CourseCard/medal-3.png');
  }

  &-num {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(67, 134, 255, 0.2);
    color: rgba(89, 89, 89, 1);
    font-size: $--cw-font-size-sm;
    text-align: center;
    border-radius: 50%;
  }
}

.course-card-cover {
  float: left;
  padding-top: 4px;
  padding-right: 14px;

  img {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}

.course-card-actions {
  text-align: right;
  line-height: 22px;
  color: rgb(16, 16, 16);
  font-size: $--cw-font-size-md;

  a {
    color: #101010;
    text-decoration: none;
    display: inline-block;
    min-width: 100px;
    text-align: right;

    i {
      margin-right: 8px;
    }

    &.active, &:hover {
      color: #cb2222;
    }
  }
}
</style>