<template>
  <div id="app">
    <template v-if="$route.meta && $route.meta.fullscreen">
      <router-view />
    </template>
    <template v-else>
      <Layout>
        <LayoutHeader />
        <LayoutContent>
          <router-view />
        </LayoutContent>
        <LayoutFooter />
      </Layout>
    </template>
  </div>
</template>

<script>
import Layout from '@/layouts/Layout.vue'
import LayoutHeader from '@/layouts/LayoutHeader.vue'
import LayoutContent from '@/layouts/LayoutContent.vue'
import LayoutFooter from '@/layouts/LayoutFooter.vue'

export default {
  components: {
    Layout,
    LayoutHeader,
    LayoutContent,
    LayoutFooter
  },
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-height: 100vh;
}

.cw-icon {
  /* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
  @font-face {
    font-family: 'iconfont';  /* Project id 4538342 */
    src: url('//at.alicdn.com/t/c/font_4538342_gj0hxohjaf6.woff2?t=1715179764409') format('woff2'),
        url('//at.alicdn.com/t/c/font_4538342_gj0hxohjaf6.woff?t=1715179764409') format('woff'),
        url('//at.alicdn.com/t/c/font_4538342_gj0hxohjaf6.ttf?t=1715179764409') format('truetype');
  }
}
</style>
