<template>
<div ref="chart" style="height: 450px;"></div>
</template>

<script>
export default {
  name: 'ChartBarLabelRotation',
  props: {
    value: Array,
    option: Object
  },
  data() {
    return {}
  },
  methods: {
    init(data) {
      const that = this
      const app = {}
      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ]

      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      }

      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          }
          myChart.setOption({
            series: [{
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          })
        }
      }

      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      }

      const xAxisData = this.value.map(item => {
        return item.name
      })

      const chartDom = this.$refs['chart']
      const myChart = this.$echarts.init(chartDom)
      const option = {
        color: [
          '#1a47e7',
          '#7e62f3',
          '#2f65bd',
          '#5dbfeb',
          '#4dc9bf'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          bottom: 0,
          data: ['关注', '收藏', '点赞', '评论']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center'
        },
        grid: {
          left: 0,
          right: 15,
          bottom: 40,
          top: 0,
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            rotate: 35, // 35度角倾斜显示
          },
          data: xAxisData
        }],
        yAxis: [{
          type: 'value'
        }],
        barWidth: '30px',
        series: [{
            name: '关注',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.value.map(group => {
              return group.value.find(item => item.name === '关注').value
            })
          },
          {
            name: '收藏',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.value.map(group => {
              return group.value.find(item => item.name === '收藏').value
            })
          },
          {
            name: '点赞',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.value.map(group => {
              return group.value.find(item => item.name === '点赞').value
            })
          },
          {
            name: '评论',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.value.map(group => {
              return group.value.find(item => item.name === '评论').value
            })
          }
        ],
        ...this.option
      }

      option && myChart.setOption(option)

      myChart.on('click', function (event) {
        const children = that.value.find(group => group.name === event.name).value.find(item => item.name === event.seriesName).children

        that.$emit('click', {
          name: event.name,
          seriesIndex: event.seriesIndex,
          seriesName: event.seriesName,
          children,
          value: event.value
        })
      })
    }
  },
  mounted() {
    this.init(this.value)
  },
  watch: {
    value: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  }
}
</script>
