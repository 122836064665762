export const requireComponents = require.context('.', true, /\.vue$/)

export default {
  install (Vue) {
    requireComponents.keys().forEach(fileName => {
      const componentConfig = requireComponents(fileName)
      const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '').replace(/\/\w+$/, '')

      Vue.component(componentName, componentConfig.default || componentConfig)
    })
  }
}
