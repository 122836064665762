<template>
<div ref="chart" style="height: 450px;"></div>
</template>

<script>
export default {
  name: 'ChartPieSectorGap',
  props: {
    value: Array,
    option: Object
  },
  data() {
    return {}
  },
  methods: {
    init(data) {
      const chartDom = this.$refs['chart']
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        dataGroupId: '',
        animationDurationUpdate: 500,
        tooltip: {
          trigger: 'item'
        },
        color: ['#e27083', '#f3d55b', '#609cf3', '#69dfbc', '#65c891', '#e0bfef'],
        series: [{
          type: 'pie',
          center: ['50%', '48%'],
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          padAngle: 5,
          itemStyle: {
            borderRadius: 10
          },
          data: this.value
        }],
        ...this.option
      }

      option && myChart.setOption(option)

      myChart.on('click', function (event) {
        if (event.data) {
          if (!event.data.children) {
            return
          }

          myChart.setOption({
            title: {
              text: event.data.name,
              subtext: '',
              left: 'center'
            },
            series: {
              type: 'pie',
              center: ['50%', '48%'],
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              padAngle: 5,
              itemStyle: {
                borderRadius: 10
              },
              data: event.data.children,
              universalTransition: {
                enabled: true,
                divideShape: 'clone'
              }
            },
            graphic: [{
              type: 'text',
              left: 0,
              top: 0,
              style: {
                text: '返回',
                fontSize: 14
              },
              onclick: function () {
                myChart.setOption(option);
              }
            }]
          })
        }
      })
    }
  },
  mounted() {
    this.init(this.value)
  },
  watch: {
    value: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  }
}
</script>
