<template>
  <div class="cw-page-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageContent'
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.cw-page-content {
    box-sizing: border-box;
    font-size: $--cw-font-size;
    line-height: 1.5;
    position: relative;
    background-color: transparent;
    margin: 28px auto 12px;
}
</style>
