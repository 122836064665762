import request from '@/utils/request';

/**
 * banner图
 */
export function fetchBanner(params) {
  return request({
    url: '/portal/conferences/banner',
    method: 'GET',
    params
  })
}
//新闻详情
export function fetchNewsInfo(params) {
  return request({
    url: '/portal/conferences/newsInfo',
    method: 'GET',
    params
  })
}
//会议议程
//新闻详情
export function fetchAgenda(params) {
  return request({
    url: '/portal/conferences/agenda',
    method: 'GET',
    params
  })
}