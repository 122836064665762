import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementUI from 'element-ui'
import ComponentsPlugin from '@/components'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import * as echarts from 'echarts'

import 'element-ui/lib/theme-chalk/index.css'

import '@/assets/fonts/iconfont/iconfont.css'

import 'normalize.css'
import './styles/reset.scss'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(fas)

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI)
Vue.use(ComponentsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//禁止F12键盘事件
/* document.addEventListener('keydown', function(event){
   return 123 != event.keyCode || (event.returnValue = false)
}) */

//禁止右键、选择、复制
/* document.addEventListener('contextmenu', function(event){
   return event.returnValue = false
}) */
