<template>
<div ref="chart" style="height: 450px;"></div>
</template>

<script>
export default {
  name: 'ChartPie',
  props: {
    value: Array,
    option: Object
  },
  data() {
    return {
      chartInstance: null
    }
  },
  methods: {
    setOption(option) {
      this.chartInstance.setOption(option)
    },
    init(data) {
      const chartDom = this.$refs['chart']
      const chartInstance = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        animationDurationUpdate: 500,
        tooltip: {
          trigger: 'item'
        },
        color: [
          '#1a47e7',
          '#7e62f3',
          '#2f65bd',
          '#5dbfeb',
          '#4dc9bf'
        ],
        series: [{
          type: 'pie',
          radius: '50%',
          itemStyle: {
            borderRadius: 2
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: this.value
        }],
        ...this.option
      }

      option && chartInstance.setOption(option)

      chartInstance.on('click', function (event) {
        if (event.data) {
          if (!event.data.children) {
            return
          }

          chartInstance.setOption({
            series: {
              type: 'pie',
              radius: '50%',
              data: event.data.children,
              universalTransition: {
                enabled: true,
                divideShape: 'clone'
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            },
            graphic: [{
              type: 'text',
              left: 0,
              top: 0,
              style: {
                text: '返回',
                fontSize: 14
              },
              onclick: function () {
                chartInstance.setOption(option);
              }
            }]
          })
        }
      })

      this.chartInstance = chartInstance
    }
  },
  mounted() {
    this.init(this.value)
  },
  watch: {
    value: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  }
}
</script>
