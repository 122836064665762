<template>
  <div class="cw-breadcrumb">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb'
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.cw-breadcrumb {
  line-height: 26px;
  font-size: 18px;
  zoom: 1;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
</style>
