import axios from 'axios'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000,
  headers: { 'Content-Type': 'application/jsoncharset=utf-8' }
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { code, msg, data } = response.data

    // 成功响应
    if (code === 200) {
      return data
    }

    // 响应数据为二进制流处理(Excel导出)
    if (data instanceof ArrayBuffer) {
      return response
    }
    
    return Promise.reject(new Error(msg || 'Error'))
  },
  (error) => {
    if (error.response.data) {
      const { code, msg } = error.response.data
    }

    return Promise.reject(error.message)
  }
)

// 导出 axios 实例
export default service
