<template>
  <div class="cw-page-header" :style="headerStyle">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    justify: {
      type: String,
      default: 'start'
    }
  },
  computed: {
    headerStyle() {
      const style = {}

      style.justifyContent = this.justify

      return style
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.cw-page-header {
    box-sizing: border-box;
    font-size: $--cw-font-size;
    line-height: 1.5;
    list-style: none;
    position: relative;
    background-color: transparent;
    padding: 10px 20px;
    margin: 28px auto 12px;
    border: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
}
</style>
