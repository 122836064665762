<template>
  <div class="cw-breadcrumb__item">
    <span :class="['cw-breadcrumb__inner', to && 'is-link']" @click="handleClick"><slot /></span>
    <i v-if="!isLastComponent" class="el-breadcrumb__separator el-icon-arrow-right"></i>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbItem',
  props: {
    to: Object
  },
  data() {
    return {
      isLastComponent: false
    }
  },
  methods: {
    handleClick() {
      if (this.to) {
        this.$router.push(this.to)
      }
    },
    last() {
      const parent = this.$parent;

      this.isLastComponent = parent.$children[parent.$children.length - 1] === this;
    }
  },
  mounted() {
    this.last()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.cw-breadcrumb__item {
  float: left;

  .is-link {
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    transition: color $--cw-motion-duration-mid $--cw-motion-ease-in-out;

    &:hover {
      color: $--cw-link-active-color;
    }
  }

  .el-breadcrumb__separator {
    margin: 0 6px;
    font-weight: 400;
  }
}
</style>
