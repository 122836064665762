import request from '@/utils/request';

/**
 * 会议基本信息
 */
export function conferencesInfo() {
  return request({
    url: '/portal/conferences/info',
    method: 'get'
  })
}

/**
 * 添加用户
 *
 * @param data
 */
/* export function addUser(data) {
  return request({
    url: '/api/v1/users',
    method: 'post',
    data: data
  })
} */

