<template>
<div ref="chart" style="height: 450px;"></div>
</template>

<script>
export default {
  name: 'ChartBar',
  props: {
    value: Array,
    option: Object
  },
  data() {
    return {}
  },
  methods: {
    init(data) {
      const chartDom = this.$refs['chart']
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        dataGroupId: '',
        animationDurationUpdate: 500,
        tooltip: {
          trigger: 'item'
        },
        color: ['#7f7be2', '#609cf3', '#ec7b94', '#f18b6a', '#f7c656', '#5dceb2', '#e1bff0'],
        series: [{
          type: 'pie',
          radius: [30, 180],
          center: ['50%', '48%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 2
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: this.value
        }],
        ...this.option
      }

      option && myChart.setOption(option)

      myChart.on('click', function (event) {
        if (event.data) {
          if (!event.data.children) {
            return
          }

          myChart.setOption({
            title: {
              text: event.data.name,
              subtext: '',
              left: 'center'
            },
            series: {
              type: 'pie',
              radius: [30, 180],
              center: ['50%', '48%'],
              roseType: 'area',
              data: event.data.children,
              universalTransition: {
                enabled: true,
                divideShape: 'clone'
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            },
            graphic: [{
              type: 'text',
              left: 0,
              top: 0,
              style: {
                text: '返回',
                fontSize: 14
              },
              onclick: function () {
                myChart.setOption(option);
              }
            }]
          })
        }
      })
    }
  },
  mounted() {
    this.init(this.value)
  },
  watch: {
    value: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  }
}
</script>
