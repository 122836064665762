<template>
  <div class="rating">
    <span v-for="index in maxRating" :key="index"
          @click="setRating(index)"
          :class="{'active': index <= currentRating}"><i class="iconfont icon-star-fill"></i></span>
  </div>
</template>

<script>
export default {
  name: 'Rate',
  props: {
    maxRating: {
      type: Number,
      default: 5
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentRating: this.value
    };
  },
  methods: {
    setRating(rating) {
      this.currentRating = rating;
      this.$emit('input', rating);
    }
  },
  watch: {
    value(newVal) {
      this.currentRating = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.rating span {
  cursor: pointer;
}

.rating span.active {
  color: #f0932b;
}
</style>